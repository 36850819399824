import React from "react"

import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "@/components/Layout"
import Seo from "@/components/Seo"
import Arrow from "@/images/arrow.svg"
import Policy from "@/sections/Policy"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo title={"Privacy Policy"} />
      <PolicyWrapper>
        <StyledLink to={"/"}>
          <GoBack>
            <img src={Arrow} alt={"Arrow"} />
            <span>Back to Akzidenz Grotesk</span>
          </GoBack>
        </StyledLink>
        <Policy />
      </PolicyWrapper>
    </Layout>
  )
}

export default PrivacyPolicy

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.darkestGray};
  }
`

const PolicyWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: span 4;

  ${({ theme }) => theme.media.tablet} {
    grid-column-start: 2;
    grid-column-end: span 4;
  }
`
const GoBack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  justify-self: end;

  background-color: ${({ theme }) => theme.colors.background};

  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 12px;
  width: 168px;
  height: 24px;
  margin-top: 35px;
  padding: 0 8px;
  color: ${({ theme }) => theme.colors.darkestGray};
  font-size: 11px;

  img {
    display: block;
    height: 7px;
    width: 22px;
  }
`
