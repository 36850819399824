import React from "react"

import {
  MainList,
  MainListItem,
  PolicyHeader,
  SubList,
  SubListItem,
  SubSubList,
  SubSubListItem,
} from "@/sections/Policy/styled"

const Policy = () => {
  return (
    <>
      <PolicyHeader>Privacy Policy</PolicyHeader>
      <MainList>
        <MainListItem>Introduction</MainListItem>
        <SubList>
          <SubListItem>
            We are committed to safeguarding the privacy of our website
            visitors. By giving you this policy we explain how we maintain and
            treat your personal information.
          </SubListItem>
          <SubListItem>
            By using our website and agreeing to this policy, you consent to our
            use of cookies in accordance with the terms of this policy.
          </SubListItem>
        </SubList>
        <MainListItem>What information do we collect about you?</MainListItem>
        <SubList>
          <SubListItem>
            We’re collecting or we may collect, store and use the following
            kinds of personal information:
          </SubListItem>
          <SubSubList>
            <SubSubListItem>
              device and browser information including IP address, cookie string
              data, operating system and (in the case of mobile devices) your
              device type and mobile device's unique identifier such as the
              Apple IDFA or Android ID, ID Token Google;
            </SubSubListItem>
            <SubSubListItem>
              information contained in or relating to any communication that you
              send to us or send through our website (including metadata
              associated with the communication);
            </SubSubListItem>
            <SubSubListItem>
              any other personal information that you decided to send to us.
            </SubSubListItem>
          </SubSubList>
          <SubListItem>
            By sending the personal information of another person, you must
            obtain that person's consent to both the disclosure and the
            processing of that personal information in accordance with this
            policy.
          </SubListItem>
        </SubList>
        <MainListItem>Why do we need your Personal information?</MainListItem>
        <SubList>
          <SubListItem>
            We may use your contact information to advertise our service to you
            on third-party websites such as Google, LinkedIn, Facebook and
            Twitter. We may also contact you directly to follow up on the
            service and user experience we provide. We use your information
            collected from the website to personalize your repeat visits to our
            website.
          </SubListItem>
          <SubListItem>
            By providing your personal data you agree to the sharing of your
            data with third parties (partners and sponsors) so that they may
            contact you. If XSolve is ever sold to another company your contact
            information will be passed on to this new party.
          </SubListItem>
          <SubListItem>
            We will take cautionary measures to ensure we do not collect any
            personal data from you we do not need in order to provide and
            oversee the above services to you.
          </SubListItem>
          <SubListItem>
            We may also use your personal information to:
          </SubListItem>
          <SubSubList>
            <SubSubListItem>personalise our website for you;</SubSubListItem>
            <SubSubListItem>
              keep our website secure and prevent fraud.
            </SubSubListItem>
          </SubSubList>
        </SubList>
        <MainListItem>
          Data sharing and disclosing personal information
        </MainListItem>
        <SubList>
          <SubListItem>
            With our service providers: We contract with companies who help with
            parts of our business operations (e.g., for example, website and
            data hosting, fraud prevention, viewability reporting, data hygiene,
            marketing, and email delivery), as well as billing, collections,
            tech, customer and operational support;
          </SubListItem>
          <SubListItem>
            With our subsidiaries and related companies: But they will only
            process your data for the purposes already explained in this Privacy
            Notice. Our subsidiaries and related companies are: XSolve Sp. z
            o.o., registration number 0000308949, Solution Expert Sp. z o.o.
            registration number 0000618844;{" "}
          </SubListItem>
          <SubListItem>
            In connection with legal proceedings: When we are under a legal
            obligation to do so, for example, to comply with a binding order of
            a court, or where disclosure is necessary to exercise, establish or
            defend the legal rights of XSolve or any other third party;
          </SubListItem>
          <SubListItem>
            To comply with legal process: To satisfy in good faith any
            applicable law, legal process, or proper governmental request, such
            as to respond to a subpoena (whether civil or criminal) or similar
            process;
          </SubListItem>
          <SubListItem>
            To Investigate Wrongdoing and Protect Ourselves or Third Parties: To
            enforce our Terms of Service or other policies or investigate any
            potential violation of those Terms and policies, any potential
            violation of the law, or to protect ourselves, our customers, or any
            third party from any potential harm (whether tangible or
            intangible);
          </SubListItem>
          <SubListItem>
            In connection with a sale of our business: If a third party acquires
            some or all of our business or assets, we may disclose your
            information in connection with the sale (including during due
            diligence in preparation for the sale);
          </SubListItem>
          <SubListItem>
            with any of our employees, officers, insurers, professional
            advisers, agents, suppliers or subcontractors insofar as reasonably
            necessary for the purposes set out in this policy.
          </SubListItem>
        </SubList>
        <MainListItem>How long will we store your data?</MainListItem>
        <SubList>
          <SubListItem>
            If XSolve provides any paid for services to you, we are required
            under Polish tax law to keep your basic personal data (name,
            address, contact details) for a minimum of 5 years after which time
            it will be destroyed. The information we use for marketing purposes
            will be kept with us until you notify us that you no longer wish to
            receive this information.
          </SubListItem>
          <SubListItem>
            We will not, without your consent including section IV point 1
            supply your personal information to any third party.
          </SubListItem>
        </SubList>
        <MainListItem>What are your rights?</MainListItem>
        <SubList>
          <SubListItem>You have the:</SubListItem>
          <SubSubList>
            <SubSubListItem>the right to access;</SubSubListItem>
            <SubSubListItem>the right to rectification;</SubSubListItem>
            <SubSubListItem>the right to erasure;</SubSubListItem>
            <SubSubListItem>the right to restrict processing;</SubSubListItem>
            <SubSubListItem>the right to object to processing;</SubSubListItem>
            <SubSubListItem>the right to data portability;</SubSubListItem>
            <SubSubListItem>
              the right to complain to a supervisory authority;
            </SubSubListItem>
            <SubSubListItem>the right to withdraw consent.</SubSubListItem>
          </SubSubList>
          <SubListItem>
            If you would like to use one of the rights from the point, please
            email or write to us at the following address: ado@boldare.com
            Address: Królowej Bony 13, 44-100 Gliwice, Poland with the request
            of using particular right.
          </SubListItem>
          <SubListItem>
            We want to make sure that your personal information is accurate and
            up to date. You may ask us to correct or remove information you
            think is inaccurate.
          </SubListItem>
          <SubListItem>
            If you are not satisfied with our response or believe we are
            processing your personal data not in accordance with the law you can
            complain to the GIODO Data Protection https://www.giodo.gov.pl/
          </SubListItem>
          <SubListItem>
            We may withhold personal information that you request to the extent
            permitted by law.
          </SubListItem>
        </SubList>
        <MainListItem id="cookies">Cookies, HotJar and Mouseflow </MainListItem>
        <SubList>
          <SubListItem>
            Cookies are text files placed on your computer to collect standard
            internet log information and visitor behavior information. This
            information is used to track visitor use of the website and to
            compile statistical reports on website activity. For further
            information visit www.aboutcookies.org or www.allaboutcookies.org.
          </SubListItem>
          <SubListItem>
            You can set your browser not to accept cookies and the above
            websites tell you how to remove cookies from your browser. However,
            in a few cases, some of our website features may not function as a
            result.
          </SubListItem>
          <SubListItem>
            We’re using also Mouseflow https://mouseflow.com/ and Hotjar
            https://www.hotjar.com/ to track your activity at our website (for
            example: which pages you visited and when, what items were clicked
            on a page, how much time was spent on a page, whether you
            downloaded).
          </SubListItem>
        </SubList>
        <MainListItem>Google Analytics</MainListItem>
        <SubList>
          <SubListItem>
            Google Analytics is a web analysis service provided by Google Inc.
            (“Google”). Google utilizes the Data collected to track and examine
            the use of this Application, to prepare reports on its activities
            and share them with other Google services. Google may use the Data
            collected to contextualize and personalize the ads of its own
            advertising network. Personal Data collected: Cookie and Usage data
          </SubListItem>
        </SubList>
        <MainListItem>Changes to this privacy policy</MainListItem>
        <SubList>
          <SubListItem>
            We keep our privacy policy under regular review and we will place
            any updates on this web page. This privacy policy was last updated
            on 21st May 2018.
          </SubListItem>
        </SubList>
        <MainListItem>Other websites</MainListItem>
        <SubList>
          <SubListItem>
            Our website contains links to other websites. This privacy policy
            only applies to this website so when you link to other websites you
            should read their own privacy policies.
          </SubListItem>
        </SubList>
        <MainListItem>Security</MainListItem>
        <SubList>
          <SubListItem>
            We apply technical, administrative and organizational security
            measures to protect the data we collect against accidental or
            unlawful destruction and loss, alteration, unauthorized disclosure
            or access, in particular where the processing involves the
            transmission of data over a network, and against other unlawful
            forms of processing.
          </SubListItem>
        </SubList>
        <MainListItem>How to contact us and our details</MainListItem>
        <SubList>
          <SubListItem>
            Please contact us if you have any questions about our privacy policy
            or information we hold about you: email: ado@boldare.com Address:
            Królowej Bony 13, 44-100 Gliwice, Poland
          </SubListItem>
          <SubListItem>This website is owned by XSolve Sp. z o. o </SubListItem>
          <SubListItem>
            We are registered in Poland in the register of entrepreneurs of the
            Regional Court in Gliwice Economic department X of the National
            Court Register under KRS number 0000308949, NIP: PL6312563443, the
            REGON: 240931698, and our registered office is at Królowej Bony 13,
            44-100 Gliwice, Poland.
          </SubListItem>
        </SubList>
      </MainList>
    </>
  )
}

export default Policy
