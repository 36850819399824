import styled from "styled-components"

export const MainList = styled.ol`
  list-style-type: decimal-leading-zero;
  list-style-position: inside;
`

export const MainListItem = styled.li`
  font-size: 28px;
  margin-top: 50px;

  ${({ theme }) => theme.media.tablet} {
    margin-top: 50px;
    font-size: 40px;
  }
`

export const SubList = styled.ol`
  list-style-type: decimal-leading-zero;
  list-style-position: outside;
  margin: 20px 0 20px 50px;

  ${({ theme }) => theme.media.tablet} {
    margin: 20px 0 20px 100px;
  }
`

export const SubListItem = styled.li`
  font-size: 20px;
  margin: 10px 0;
`

export const SubSubList = styled.ol`
  list-style-type: lower-latin;
  list-style-position: outside;
  margin: 5px 0 5px 50px;
`

export const SubSubListItem = styled.li`
  font-size: 20px;
`

export const PolicyHeader = styled.h3`
  font-size: 64px;
  margin-top: 64px;
  letter-spacing: -0.02rem;
`
